import { useQuery } from '@tanstack/react-query';
import ClientService from '../services/ClientService';
import { useCurrentClient } from '../global-state/Clients';

const useFetchClientUsers = (includeDisabledUsers?: boolean) => {
  const currentClient = useCurrentClient((x) => x.value);
  return useQuery({
    queryKey: ['clientUsers', currentClient?.id, includeDisabledUsers],
    staleTime: Infinity,
    queryFn: () => {
      return ClientService.getUsers(includeDisabledUsers);
    },
  });
};

export default useFetchClientUsers;
